import _ from 'lodash'
import { getColorHex, getContrastingTextForBackground, joinClassNames, safelyMillify } from 'utils'
import { useEffect, useRef, useState, useMemo } from 'react'
import { setModalShow } from 'redux/modalsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import SocialIcon from './SocialIcon'

const TeamInfoBox = ({ backgroundColor, mainStatistic, description }) => {
  return (
    <div
      className="mx-3 p-4 rounded"
      style={{
        backgroundColor,
      }}
    >
      <div className="fw-bold fs-2">{mainStatistic}</div>
      <div
        className=""
        style={{
          fontSize: '1.1rem',
        }}
      >
        {description}
      </div>
    </div>
  )
}

export default function PublicRosterHeading({
  talentSheet,
  displaySettings = {},
  teamRoster = true,
  showTeamDescription = true,
  showTitle = false,
}) {
  const dispatch = useDispatch()
  const titleFont = _.get(talentSheet, 'settings.displayRosterTitleFont') || 'barlow'

  const influencers = useSelector((state) => state.influencers)

  const reach = useSelector((state) => {
    if (teamRoster) return state.team.reach
    return talentSheet.reach
  })

  const total_influencers = teamRoster
    ? influencers.ids.length
    : talentSheet.influencer_ids?.length

  const totalFollowers = useMemo(() => {
    if (_.isEmpty(reach)) return null
    const total = Object.values(reach).reduce((sum, value) => sum + value, 0)

    return total
  }, [reach])

  const darkMode = displaySettings.displayDarkMode

  // `displayDarkLogo` says that the logo is already dark, so we don't need to invert it
  const imageShouldInvert = darkMode && !displaySettings.displayDarkLogo

  const mainContainerRef = useRef(null)
  const [textColorClass, setTextColorClass] = useState('text-black')

  const backgroundColor = useMemo(() => {
    let bgColor = displaySettings?.displayProfileBackground
      ? displaySettings.displayRosterBackgroundColor
      : 'white'
    return bgColor
  }, [displaySettings])

  const backgroundColorHex = useMemo(() => {
    if (!displaySettings?.displayProfileBackground) return getColorHex('white')
    return getColorHex(displaySettings.displayRosterBackgroundColor)
  })

  const styles = {
    backgroundColor: backgroundColorHex,
  }

  useEffect(() => {
    if (!backgroundColorHex) return
    const contrastingTextColor = getContrastingTextForBackground(backgroundColorHex)
    setTextColorClass(`text-${contrastingTextColor}`)
  }, [displaySettings.displayProfileBackground])

  if (!(talentSheet.team_image_url || talentSheet.team_name)) {
    return null
  }

  const hasContactInfo =
    !_.isEmpty(talentSheet?.team_contact_email) ||
    !_.isEmpty(talentSheet?.team_phone) ||
    !_.isEmpty(talentSheet?.team_homepage)

  const activateContactModal = () => {
    if (!hasContactInfo) return
    dispatch(setModalShow({ modal: 'contactInfo', show: true }))
  }

  const colors = {
    lightGreen: '#f1ffff',
    lightRed: '#fff4f5',
    lightBlue: '#f3f9ff',
    brightPurple: '#8721ad',
    brightRed: '#ed353a',
  }

  const audienceBoxes = [
    {
      platform: 'instagram',
      color: colors.brightPurple,
    },
    {
      platform: 'tiktok',
      color: 'black',
    },
    {
      platform: 'youtube',
      color: colors.brightRed,
    },
  ]

  return (
    <div
      ref={mainContainerRef}
      className={joinClassNames([`container-fluid`, `bottom-drop-shadow`, 'bg-white'])}
    >
      <div className="row py-2">
        <div
          className={joinClassNames(['col-12 col-md-6', hasContactInfo ? 'selectable' : null])}
          onClick={activateContactModal}
        >
          <div className="row">
            <div
              className={joinClassNames([
                'col-4 text-center d-flex align-items-center',
                darkMode ? 'bg-gray-900' : null,
              ])}
            >
              <img
                style={{ maxHeight: '120px', maxWidth: '100%' }}
                className={joinClassNames([
                  // `profile-team-logo`,
                  // imageShouldInvert ? 'dark-mode-image' : null,
                ])}
                src={talentSheet.team_image_url}
              />
            </div>
            <div className="col-6 d-flex flex-column justify-content-center pb-3">
              {showTeamDescription && (
                <>
                  <div className={joinClassNames([`text-bold pb-2`, 'fs-4'])}>
                    {talentSheet.team_name}
                  </div>
                  <div style={{ fontSize: '.9rem' }}>{talentSheet?.team_bio}</div>
                  {hasContactInfo && (
                    <div className="d-flex flex-column justify-content-center">
                      <button className="btn btn-link" onClick={activateContactModal}>
                        Contact
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 col-md-5">
          <div className="d-flex flex-column flex-md-row justify-content-center">
            <TeamInfoBox
              backgroundColor={colors.lightGreen}
              mainStatistic={total_influencers}
              description="No of Creators"
            />
            {totalFollowers && (
              <>
                <TeamInfoBox
                  backgroundColor={colors.lightRed}
                  mainStatistic={safelyMillify(totalFollowers)}
                  description="Total Reach"
                />
                <TeamInfoBox
                  backgroundColor={colors.lightBlue}
                  mainStatistic={'Social Platforms'}
                  description={
                    <>
                      <div className="d-flex justify-content-around">
                        {audienceBoxes.map((box) => {
                          if (!reach[box.platform]) return null
                          return (
                            <div
                              style={{
                                borderBottom: `3px solid ${box.color}`,
                              }}
                              className="d-flex align-content-center"
                            >
                              <SocialIcon platform={box.platform} />
                              <span className="ms-1">{safelyMillify(reach[box.platform])}</span>
                            </div>
                          )
                        })}
                      </div>
                    </>
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
