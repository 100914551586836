import React, { useState, useEffect, useMemo } from 'react'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import useLocalStorage from 'hooks/useLocalStorage'
import _, { create } from 'lodash'
import {
  fetchOneUserSheet,
  setNameFilter,
  saveUserSheet,
  debouncedSaveUserSheet,
  setTitle,
} from 'redux/userSheetSlice'
import { useParams } from 'react-router'
import 'react-grid-layout/css/styles.css'

import {
  EuiPageTemplate,
  EuiPageTemplateProps,
  EuiPageSidebarProps,
  EuiButton,
  EuiFieldText,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  useGeneratedHtmlId,
  EuiFormRow,
  EuiCheckbox,
  EuiToolTip,
} from '@elastic/eui'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { setModalShow } from 'redux/modalsSlice'

const DuplicateSheetModal = ({ panelled, offset, grow, sheetId }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()

  const modalTitleId = useGeneratedHtmlId()

  const [modalSheetTitle, setModalSheetTitle] = useState('')
  const [usePublicTitle, setUsePublicTitle] = useState(false)
  const [error, setError] = useState(null)

  const duplicatedSheet = useSelector((state) => state.allSheets.entities[sheetId])

  const createDuplicateSheet = async () => {
    const data = {
      title: modalSheetTitle,
      description: usePublicTitle ? modalSheetTitle : undefined,
      sheet_type: 'talent',
      influencers: [],
    }

    if (_.isEmpty(data.title)) {
      setError('You must provide a Sheet Name')

      setTimeout(() => {
        setError(null)
      }, 1500)
      return
    }

    const duplicatedIds = duplicatedSheet?.influencer_ids || []

    data.influencers = duplicatedIds.map((influencer_id) => {
      return {
        influencer_id,
      }
    })

    try {
      const response = await axios.post('/api/user/talent-sheets/save', data)
      if (response.data.sheet_id) {
        dispatch(setModalShow({ modal: 'duplicateSheet', show: false }))
        navigate(`/talent-sheet/${response.data.sheet_id}`)
      }
    } catch (e) {
      // @ts-ignore
      const error = _.get(e, 'response.data.error')
      throw new Error(error)
    }
  }

  if (!duplicatedSheet) return null

  return (
    <EuiPageTemplate panelled={panelled} offset={offset} grow={grow}>
      <>
        <EuiModal
          aria-labelledby={modalTitleId}
          onClose={() => {
            dispatch(setModalShow({ modal: 'duplicateSheet', show: false }))
            setModalSheetTitle('')
            navigate('/talent-sheets')
          }}
        >
          <EuiModalHeader>
            <EuiModalHeaderTitle id={modalTitleId}>
              Make Copy of {duplicatedSheet.title}
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiFormRow label="Sheet Name">
              <EuiFieldText
                name="sheet_name"
                value={modalSheetTitle}
                onChange={(e) => setModalSheetTitle(e.target.value)}
              />
            </EuiFormRow>
            <EuiFormRow>
              <EuiToolTip content='If not checked, the title will display as "Selected Talent" when visiting the public link'>
                <EuiCheckbox
                  id="usePublicTitle"
                  label="Display above title when sharing"
                  checked={usePublicTitle}
                  onChange={(e) => setUsePublicTitle(e.target.checked)}
                />
              </EuiToolTip>
            </EuiFormRow>
          </EuiModalBody>

          <EuiModalFooter>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <EuiButton
              onClick={() => {
                createDuplicateSheet()
              }}
              fill
            >
              Copy
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </>
    </EuiPageTemplate>
  )
}

export default DuplicateSheetModal
