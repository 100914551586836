import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import axios from 'axios'
import { fetchResponseSheet } from './castingCallsSlice'

export const fetchOneUserSheet = createAsyncThunk(
  'talentsheet/fetchOne',
  async ({ hash_id, sheet_type }) => {
    let route = `/api/talent-sheets/${hash_id}`
    if (sheet_type) {
      route = `/api/talent-sheets/${hash_id}?sheet_type=${sheet_type}`
    }
    const response = await axios.get(route)
    return response.data
  },
)

const defaultSheet = {
  id: null,
  title: '',
  description: null,
  updated_at: '',
  created_at: '',
  creator_user_id: '',
  team_id: '',
  sheet_type: 'talent',
  creator_nickname: '',
  creator_full_name: '',
  creator_email: '',
  influencers: {},
  error: null,
  categories: [],
  loading: 'idle',
  saveStatus: 'clean',
  usePublicTitle: false,
  hasUnsavedChanges: false,
  influencer_ids: [],
  notes: {},
  name_filter: '',
  tag_filters: [],
  filteredInfluencers: [],
  castingCallResponseId: null,
  previous_influencer_ids: [],
}

export const saveUserSheet = createAsyncThunk(
  'userSheet/save',
  async (passedParam, { getState, dispatch }) => {
    const { userSheet } = getState()
    const { title, sheet_type, influencers, notes, influencer_ids } = userSheet

    const data = {
      title,
      sheet_type,
      influencers: influencer_ids.map((influencer_id) => {
        return {
          influencer_id,
          note: notes[influencer_id],
        }
      }),
    }

    if (userSheet.id) {
      data['sheet_id'] = userSheet.id
    }

    if (userSheet.castingCallResponseId) {
      data['casting_call_id'] = userSheet.castingCallResponseId
    }

    try {
      const response = await axios.post('/api/user/talent-sheets/save', data)
      if (response.data.sheet_id) {
        dispatch(userSheetSlice.actions.setId(response.data.sheet_id))
      }

      return data
    } catch (e) {
      // @ts-ignore
      const error = _.get(e, 'response.data.error')
      throw new Error(error)
    }
  },
)

export const debouncedSaveUserSheet = createAsyncThunk(
  'userSheet/debouncedSave',
  (__, { dispatch, getState }) => {
    const debouncedSave = _.debounce(() => {
      const { userSheet } = getState()

      if (userSheet.saveStatus === 'clean') return

      dispatch(saveUserSheet())
    }, 1500)
    debouncedSave()
  },
)

export const addInfluencerAndSave = createAsyncThunk(
  'userSheet/addInfluencerAndSave',
  (payload, { dispatch }) => {
    dispatch(userSheetSlice.actions.addInfluencer(payload))
    dispatch(debouncedSaveUserSheet())
  },
)

export const removeInfluencerAndSave = createAsyncThunk(
  'userSheet/removeInfluencerAndSave',
  (payload, { dispatch }) => {
    dispatch(userSheetSlice.actions.removeInfluencer(payload))
    dispatch(debouncedSaveUserSheet())
  },
)

export const setInfluencersAndSave = createAsyncThunk(
  'userSheet/setInfluencerAndSave',
  (payload, { dispatch, getState }) => {
    // const previousIds = getState().userSheet.influencer_ids
    // dispatch(userSheetSlice.actions.setPreviousInfluencerIds(previousIds))

    dispatch(userSheetSlice.actions.setInfluencers(payload))
    dispatch(debouncedSaveUserSheet())
  },
)

export const saveNote = createAsyncThunk('userSheet/saveNote', (payload, { dispatch }) => {
  dispatch(userSheetSlice.actions.setNote(payload))
  dispatch(debouncedSaveUserSheet())
})

export const savePublicSheetHeading = (newHeading, talentSheetId) => {
  return axios.post('/api/user/talent-sheets/public-title', {
    public_title: newHeading,
    sheet_id: talentSheetId,
  })
}

export const userSheetSlice = createSlice({
  name: 'userSheet',
  initialState: {
    ...defaultSheet,
  },
  reducers: {
    setInfluencers: (state, { payload }) => {
      state.influencer_ids = payload
      state.saveStatus = 'dirty'
      state.hasUnsavedChanges = true
    },
    addInfluencer: (state, { payload }) => {
      state.influencer_ids.push(payload)
      state.saveStatus = 'dirty'
      state.hasUnsavedChanges = true
    },
    removeInfluencer: (state, { payload }) => {
      state.influencer_ids = state.influencer_ids.filter((id) => id !== payload)
      state.saveStatus = 'dirty'
      state.hasUnsavedChanges = true
    },
    updateNotes: (state, { payload }) => {
      Object.keys(payload.notes).forEach((key) => {
        state.influencers[key].note = payload.notes[key]
      })
      state.saveStatus = 'dirty'
      state.hasUnsavedChanges = true
    },
    setTitle: (state, { payload }) => {
      state.title = payload
      state.saveStatus = 'dirty'
      state.hasUnsavedChanges = true
    },
    setId: (state, { payload }) => {
      state.id = payload
    },
    setFilteredInfluencers: (state, { payload }) => {
      state.filteredInfluencers = payload
    },
    resetSheet: () => {
      return {
        ...defaultSheet,
      }
    },
    setNote: (state, { payload }) => {
      state.notes[payload.id] = payload.note
      state.saveStatus = 'dirty'
      state.hasUnsavedChanges = true
    },
    setCategories: (state, { payload }) => {
      state.categories = payload
    },
    setCampaignDetails: (state, { payload }) => {
      state.notes[payload.id] = payload.note
    },
    setSaveStatus: (state, { payload }) => {
      state.saveStatus = payload
      state.hasUnsavedChanges = false
    },
    setDescription: (state, { payload }) => {
      state.description = payload
      if (payload === null) {
        state.description = ''
        state.usePublicTitle = false
      } else {
        state.usePublicTitle = true
      }
    },
    setNameFilter: (state, { payload }) => {
      state.name_filter = payload
    },
    setTagFilters: (state, { payload }) => {
      state.tag_filters = payload
    },
    setCastingCallResponse: (state, { payload }) => {
      state.castingCallResponseId = payload
    },
    setSheetType: (state, { payload }) => {
      state.sheet_type = payload
    },
    setPreviousInfluencerIds: (state, { payload }) => {
      state.previous_influencer_ids = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOneUserSheet.pending, (state) => {
        state.loading = 'pending'
      })
      .addCase(fetchOneUserSheet.fulfilled, (state, { payload }) => {
        const fetchedSheet = payload.sheet

        return {
          ...state,
          ...fetchedSheet,
          usePublicTitle: fetchedSheet.description !== null,
          loading: 'finished',
          saveStatus: 'clean',
          hasUnsavedChanges: false,
        }
      })
      .addCase(fetchResponseSheet.fulfilled, (state, { payload }) => {
        console.log(payload)
        if (!payload.talent_sheet_id) {
          return {
            ...defaultSheet,
            castingCallResponseId: payload.casting_call_id,
            sheet_type: 'casting_call_response',
          }
        }

        return
        const { responseSheet } = payload
        const { influencers } = responseSheet
        const influencer_ids = Object.keys(influencers)
        const notes = _.mapValues(influencers, (influencer) => influencer.note)
        return {
          ...state,
          ...responseSheet,
          influencer_ids,
          notes,
          usePublicTitle: responseSheet.description !== null,
          loading: 'finished',
          saveStatus: 'clean',
          hasUnsavedChanges: false,
        }
      })
      .addCase(saveUserSheet.fulfilled, (state) => {
        state.saveStatus = 'clean'
        state.hasUnsavedChanges = false
      })
  },
})

// Action creators are generated for each case reducer function
export const {
  setInfluencers,
  setTitle,
  setNote,
  resetSheet,
  setCategories,
  setSaveStatus,
  setDescription,
  updateNotes,
  setNameFilter,
  setTagFilters,
  addInfluencer,
  removeInfluencer,
  setFilteredInfluencers,
  setCastingCallResponse,
  setSheetType,
} = userSheetSlice.actions

export default userSheetSlice.reducer
