import { useEffect, useRef, useMemo } from 'react'
import normalMillify from 'millify'

import _ from 'lodash'
import { joinClassNames } from 'utils'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'

import { performUpdate, setSortBy, setSortOrder } from 'redux/searchSlice'
import { useParams, Link } from 'react-router-dom'
import SocialIcon from './SocialIcon'

const millify = (num) => {
  if (!num) {
    return ''
  }
  return normalMillify(num)
}

function roundToDecimalPlace(num, places = 1) {
  if (!num) return null
  const factor = Math.pow(10, places)
  return Math.round(num * factor) / factor
}

const getViews = (social) => {
  if (social.platform === 'instagram') {
    return social.avg_reels_plays
  }
  return social.avg_views
}

const DisplaySearchResults = ({
  results,
  blankMessage,
  exclude_ids,
  handleAdd,
  handleAddMultiple,
}) => {
  const dispatch = useDispatch()
  const urlParams = useParams()
  const searchParams = useSelector((state) => state.search.params)
  const { hasMoreResults, nextPage, loadingState } = useSelector((state) => state.search)

  const columns = [
    {
      name: 'Talent',
      size: '4',
      sortValue: ({ social, influencer }) => {
        return influencer.fullname || social.platform_account_nickname
      },
      data: ({ social, influencer }) => {
        const genderAge = [
          influencer?.gender ? influencer.gender[0] : null,
          influencer?.age_range ? influencer.age_range : null,
        ]
          .filter(Boolean)
          .join(' / ')

        return (
          <div className="row align-items-center">
            <div className="col-3">
              <Link className="text-decoration-none" to={`/influencers/profile/${influencer.id}`}>
                <img
                  style={{
                    width: '100%',
                    // height: '80px',
                  }}
                  src={social.picture_main_url}
                  alt={social.platform_account_nickname}
                  className="rounded-circle"
                />
              </Link>
            </div>
            <div className="col-7">
              <Link className="text-decoration-none" to={`/influencers/profile/${influencer.id}`}>
                <div className="d-flex flex-column justify-content-between talent-name text-black">
                  <div className="fw-bold fullname">{influencer.fullname}</div>
                  <div className="fst-italic handle">
                    <SocialIcon platform={social.platform} size={14} />
                    <span className="ms-1 text-gray">{social.platform_account_nickname}</span>
                  </div>
                  {!_.isEmpty(genderAge) && (
                    <div
                      style={{
                        fontSize: '.85em',
                      }}
                    >
                      {genderAge}
                    </div>
                  )}
                  <div className="text-blue">
                    View Profile <span className="pb-1 text-blue fs-3">→</span>
                  </div>
                </div>
              </Link>
              {/* <Link to={`/influencers/${influencer.id}`}>
                {_.uniq([influencer.fullname, social.platform_account_nickname])
                  .filter(Boolean)
                  .join(' / ')}
              </Link> */}
            </div>
          </div>
        )
      },
    },
    false && {
      id: 'name',
      name: 'Name/Handle',
      size: '2',
      sortValue: ({ social, influencer }) => {
        return influencer.fullname || social.platform_account_nickname
      },
      data: ({ social, influencer }) => {
        return (
          <>
            <Link to={`/influencers/${influencer.id}`}>
              {_.uniq([influencer.fullname, social.platform_account_nickname])
                .filter(Boolean)
                .join(' / ')}
            </Link>
          </>
        )
      },
    },
    {
      id: 'us_audience_share',
      name: 'US Audience',
      size: '1',
      sortValue: ({ social, influencer }) => {
        return Number(social.us_audience_share)
      },
      data: ({ social, influencer }) => {
        if (!social.us_audience_share || Number(social.us_audience_share) === 0) return null
        return `${Math.round(social.us_audience_share)}%`
      },
    },
    {
      name: 'Female/Male',
      sortValue: ({ social, influencer }) => {
        return Number(social.audience_female_percent)
      },
      size: '1',
      data: ({ social, influencer }) => {
        if (!social.audience_female_percent && !social.audience_male_percent) return null
        return `${Math.round(social.audience_female_percent)}% / ${Math.round(
          social.audience_male_percent,
        )}%`
      },
    },
    {
      id: 'followers',
      name: 'Followers',
      size: '1',
      sortValue: ({ social, influencer }) => {
        return social.followers
      },
      data: ({ social, influencer }) => {
        if (!social.followers) return null
        return millify(social.followers)
      },
    },
    searchParams.min_age_share &&
      searchParams.min_age_share && {
        id: 'audience_age_share',
        name: `Audience ${searchParams.audience_age_min} - ${searchParams.audience_age_max}`,
        size: '1',
        // sortValue: ({ social, influencer }) => {
        //   const audienceParam = searchParams.audience_age_range.replace('-', '_')
        //   return social[`audience_age_share_${audienceParam}`]
        // },
        data: ({ social, influencer }) => {
          // return "HELLO"
          let audienceAgeDescriptor = [
            searchParams.audience_age_min,
            searchParams.audience_age_max,
          ].join('_')

          const audienceParam = searchParams.audience_age_min
          const sharePct = social[`audience_age_share_${audienceAgeDescriptor}`]
          if (!sharePct) return null
          return `${Math.round(sharePct)}%`
        },
      },
    {
      id: 'engagement_rate',
      name: 'Engagement Rate',
      size: '1',
      sortValue: ({ social, influencer }) => {
        return Number(social.engagement_rate)
      },
      data: ({ social, influencer }) => {
        if (!social.engagement_rate) return null
        return `${roundToDecimalPlace(social.engagement_rate)}%`
      },
    },
    {
      id: 'avg_views',
      name: 'Average Views',
      size: '1',
      sortValue: ({ social, influencer }) => {
        return getViews(social) || 0
      },
      data: ({ social, influencer }) => {
        return social.platform === 'instagram'
          ? millify(social.avg_reels_plays)
          : millify(social.avg_views)
      },
    },
    {
      id: 'add_button',
      name: 'Add',
      size: '1',
      data: ({ social, influencer }) => {
        return (
          <button className="btn btn-sm btn-bright-blue" onClick={() => handleAdd(influencer.id)}>
            Add
          </button>
        )
      },
    },
  ].filter(Boolean)

  const loadNextPage = () => {
    if (loadingState === 'pending' || loadingState === 'initial' || loadingState === 'appending') {
      return
    }
    dispatch(performUpdate({ append: true, team_id: urlParams.team_id, page: nextPage }))
  }

  const loadMoreRef = useRef(null)

  useEffect(() => {
    if (hasMoreResults) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            loadNextPage()
          }
        },
        { threshold: 1 },
      )

      if (loadMoreRef.current) {
        observer.observe(loadMoreRef.current)
      }

      return () => {
        if (loadMoreRef.current) {
          observer.unobserve(loadMoreRef.current)
        }
      }
    }
  }, [loadMoreRef, hasMoreResults]) // Observing for changes to these dependencies

  const socials = useSelector((state) => state.socials.entities)
  const influencers = useSelector((state) => state.influencers.entities)

  const sortBy = useSelector((state) => state.search.sortBy)
  const sortOrder = useSelector((state) => state.search.sortOrder)

  const orderByColumn = (result) => {
    const column = columns.find((column) => column.id === sortBy)

    if (!column || !column.sortValue) {
      return 0
    }

    return column.sortValue({
      social: {
        ...socials[result.social_account_id],
        ...result,
      },
      influencer: influencers[result.influencer_id],
    })
  }

  let uniqueResults = useMemo(() => {
    return _.uniqBy(results, (result) => {
      return `${result.social_account_id}-${result.social_account_id}}`
    })
  }, [results])

  if (exclude_ids) {
    uniqueResults = uniqueResults.filter((result) => !exclude_ids.includes(result.influencer_id))
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row my-2">
          {uniqueResults.length > 0 && (
            <div className="col-12">
              <button
                className="btn btn-bright-blue text-white btn-lg"
                onClick={() => {
                  const visibleInfluencerIds = uniqueResults.map((result) => result.influencer_id)
                  handleAddMultiple(visibleInfluencerIds)
                }}
              >
                Add All to Sheet
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="container mt-3 custom-table shadow">
        <div className="row bg-gray-800 text-white fw-bold table-head-row justify-content-between">
          {columns.map((column, index) => {
            const size = column.size || '2'

            return (
              <div
                key={`th-${index}`}
                className={joinClassNames([
                  `col-${size} table-cell text-center d-flex align-items-center justify-content-center`,
                  column.sortValue && 'selectable',
                ])}
                onClick={() => {
                  if (!column.sortValue) return
                  if (sortBy === column.id) {
                    dispatch(setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'))
                  } else {
                    dispatch(setSortBy(column.id))
                    dispatch(setSortOrder('desc'))
                  }
                }}
              >
                {column.name}
                {sortBy === column.id && (
                  <span style={{ marginLeft: '4px' }}>{sortOrder === 'asc' ? '▲' : '▼'}</span>
                )}
              </div>
            )
          })}
        </div>
        {_.isEmpty(results) ? (
          <div className="row table-body-row">
            <div className="col-12 text-center py-3">
              {blankMessage || 'No Matching Results. Try removing some filters'}
            </div>
          </div>
        ) : (
          <>
            {_.orderBy(uniqueResults, orderByColumn, sortOrder).map((result, index) => {
              const social = socials[result.social_account_id] || {}
              const influencer = influencers[result.influencer_id]

              if (!influencer || !social) {
                console.log('Missing influencer or social', result)
                return null
              }

              return (
                <div key={`row-${index}`} className="row table-body-row justify-content-between">
                  {columns.map((column, index) => {
                    const size = column.size || '2'
                    return (
                      <div
                        key={`row-${index}`}
                        className={`col-${size} table-cell text-center d-flex flex-column justify-content-center`}
                      >
                        {column.data({
                          social: {
                            ...social,
                            ...result,
                          },
                          influencer,
                        })}
                      </div>
                    )
                  })}
                </div>
              )
            })}
            {hasMoreResults && (
              <div
                style={{
                  marginTop: '80px',
                }}
                ref={loadMoreRef}
              >
                .
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default DisplaySearchResults
