import React, { useState } from 'react'
import {
  EuiModal,
  EuiButton,
  EuiConfirmModal,
  EuiFlexGroup,
  EuiFlexItem,
  useGeneratedHtmlId,
} from '@elastic/eui'
import { performSearch, selectUniqueResults } from 'redux/searchSlice'
import { useDispatch, useSelector } from 'react-redux'
import DisplaySearchResults from './DisplaySearchResults'
import { Modal, Button } from 'react-bootstrap'
import { setModalShow } from 'redux/modalsSlice'
import SearchArea from './SearchArea'

const AdvancedFiltersModal = ({
  talentSheet = {},
  handleAdd = (i) => {
    console.log('add', i)
  },
  handleAddMultiple = () => {},
}) => {
  const searchResults = useSelector((state) => selectUniqueResults(state))
  const show = useSelector((state) => state.modals.advancedFilters)
  const dispatch = useDispatch()

  const setShow = (show) => dispatch(setModalShow({ modal: 'advancedFilters', show }))

  const handleClose = () => {
    setShow(false)
  }

  return (
    <>
      <Modal size="xl" show={show} onHide={handleClose} enforceFocus={false}>
        <Modal.Header closeButton>
          <Modal.Title>Advanced Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-10">
                <SearchArea />
              </div>
            </div>
            <DisplaySearchResults
              results={searchResults}
              handleAdd={handleAdd}
              handleAddMultiple={handleAddMultiple}
              exclude_ids={talentSheet.influencer_ids}
              blankMessage="No results to show"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-bright-blue" onClick={handleClose}>
            Done
          </button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AdvancedFiltersModal
