import React, { useState } from 'react'
import {
  EuiModal,
  EuiButton,
  EuiConfirmModal,
  EuiFlexGroup,
  EuiFlexItem,
  useGeneratedHtmlId,
} from '@elastic/eui'
import { performSearch, selectUniqueResults } from 'redux/searchSlice'
import { useDispatch, useSelector } from 'react-redux'
import DisplaySearchResults from './DisplaySearchResults'
import { Modal, Button } from 'react-bootstrap'
import { setModalShow } from 'redux/modalsSlice'
import BasicGrid from './BasicGrid'
import { ListManager } from 'react-beautiful-dnd-grid'
import { Talent } from 'helpers/Talent'
import { setInfluencers, setInfluencersAndSave } from 'redux/userSheetSlice'
import { Link } from 'react-router-dom'

const ListElement = ({ item }) => {
  return (
    <div className="grid-drag-item">
      <div className={``} data-type="influencer-onsheet">
        <div className="portrait-rounded text-center mb-3">
          <Link
            // target="_blank"
            to={`/influencers/profile/${item.content.id}`}
          >
            <img src={item.content.picture} />
          </Link>
        </div>
        <div className={`col-12 text-center`}>
          <div className="mb-3">
            <p className="w-100">{item.content.fullname}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const ReorderModal = ({ talentSheetInfluencers = [], show, setShow }) => {
  const dispatch = useDispatch()

  const talentSheet = useSelector((state) => state.userSheet)

  const socials = useSelector((state) => state.socials.entities)

  const influencers = useSelector((state) => state.influencers.entities)

  const influencerToDraggable = (influencer) => {
    const talent = new Talent(influencer, socials)

    return {
      id: `influencer-${influencer.id}`,
      content: {
        fullname: influencer.fullname,
        picture: talent.getPhotoUrl(),
        email: influencer.email,
        id: influencer.id,
        tags: influencer.tags,
      },
    }
  }

  function onDragEnd(result) {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      return
    }

    const new_influencer_ids = reorder(talentSheet.influencer_ids, source.index, destination.index)
    dispatch(setInfluencersAndSave(new_influencer_ids))
  }

  let draggableSheetInfluencers = talentSheetInfluencers
    .map((id) => {
      return influencers[id]
    })
    .filter((inf) => inf)
    .map(influencerToDraggable)

  const handleClose = () => {
    setShow(false)
  }

  return (
    <>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <ListManager
              items={draggableSheetInfluencers}
              direction="horizontal"
              maxItems={5}
              render={(item) => <ListElement item={item} />}
              onDragEnd={(sourceIndex, destinationIndex) => {
                onDragEnd({
                  source: { index: sourceIndex },
                  destination: { index: destinationIndex },
                })
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-bright-blue" onClick={handleClose}>
            Done
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ReorderModal
